var DmaSelect = (() => {
    const selectSelector = "select";

    const className = "select--dma";
    const classInitialized = "is-initialized";

    let selects = [];

    let init = () => {
        selectSelects();

        hide();

        selects.forEach((select) => {
            if (!select.classList.contains(classInitialized)) {
                createSelect(select);
            }
        });
    };
    let insertAfter = function (appendto, newelement) {
        appendto.parentNode.insertBefore(newelement, appendto.nextSibling);
    };

    let createSelect = (select) => {
        let dropdown = document.createElement("div");
        dropdown.classList.add(className);

        select.classList.add(classInitialized);

        // Todo: class from select
        // Todo: disabled from select
        // Todo: tabindex from dropdown
        dropdown.tabIndex = 0;

        let current = document.createElement("span");
        current.classList.add("current");

        let list = document.createElement("ul");
        list.classList.add("list");

        let options = select.querySelectorAll("option");

        let selected = options[select.selectedIndex];
        current.innerHTML = selected.innerText;

        options.forEach((option, index) => {
            let optionItem = document.createElement("li");
            optionItem.innerHTML = option.innerText;
            optionItem.dataset.value = option.value;
            if (index === select.selectedIndex) {
                optionItem.classList.add("selected");
            }
            optionClickHandler(optionItem, current, select, dropdown);
            list.append(optionItem);
        });

        dropdown.append(current, list);

        insertAfter(select, dropdown);

        dropdownClickHandler(dropdown);
    };

    let optionClickHandler = (optionItem, current, select, dropdown) => {
        optionItem.addEventListener("click", () => {
            // Todo: check for disabled option

            dropdown.querySelectorAll("li.selected").forEach((oldSelectedOption) => {
                oldSelectedOption.classList.remove("selected");
            });

            current.innerHTML = optionItem.innerHTML;
            select.value = optionItem.dataset.value;
            select.dispatchEvent(new Event("change"));
            optionItem.classList.add("selected");
        });
    };

    let dropdownClickHandler = (dropdown) => {
        dropdown.addEventListener("click", () => {
            // Todo: close all other selects
            if (dropdown.classList.contains("select--open")) {
                dropdown.classList.remove("select--open");
            } else {
                dropdown.classList.add("select--open");
                dropdown.focus();
            }
        });

        let options = dropdown.querySelectorAll("li");
        let focusedOption = -1;

        dropdown.addEventListener("keydown", (event) => {
            let keyCodeMatched = false;
            switch (event.keyCode) {
                case 32: // Space
                    keyCodeMatched = true;
                    if (dropdown.classList.contains("select--open")) {
                        dropdown.classList.remove("select--open");
                    } else {
                        dropdown.classList.add("select--open");
                        dropdown.focus();
                    }
                    break;
                case 13: // Enter
                    keyCodeMatched = true;
                    if (dropdown.classList.contains("select--open")) {
                        // check for focused option
                        options.forEach((option, index) => {
                            if (option.classList.contains("focus")) {
                                option.click();
                            }
                        });

                        dropdown.classList.remove("select--open");
                    } else {
                        dropdown.classList.add("select--open");
                        dropdown.focus();
                    }
                    break;
                case 40: // Down
                    keyCodeMatched = true;

                    focusedOption++;

                    if (!dropdown.classList.contains("select--open")) {
                        dropdown.classList.add("select--open");
                        dropdown.focus();
                    }

                    if (focusedOption > options.length - 1) {
                        focusedOption = options.length - 1;
                    }

                    options.forEach((option, index) => {
                        option.classList.remove("focus");
                        if (index === focusedOption) {
                            option.classList.add("focus");
                        }
                    });

                    break;
                case 38: // Up
                    keyCodeMatched = true;
                    focusedOption--;
                    if (focusedOption < -1) {
                        focusedOption = -1;
                    }

                    if (focusedOption === -1) {
                        dropdown.classList.remove("select--open");
                    }

                    options.forEach((option, index) => {
                        option.classList.remove("focus");
                        if (index === focusedOption) {
                            option.classList.add("focus");
                        }
                    });

                    break;
                case 27: // Esc
                    keyCodeMatched = true;
                    dropdown.classList.remove("select--open");
                    break;
                case 9: // Tab
                    keyCodeMatched = true;

                    break;
            }

            if (keyCodeMatched) {
                event.preventDefault();
            }

            return !keyCodeMatched; // return false if keyCodeMatched
        });

        document.addEventListener("click", (event) => {
            if (!dropdown.contains(event.target)) {
                dropdown.classList.remove("select--open");
            }
        });
    };

    let selectSelects = () => {
        selects = document.querySelectorAll(selectSelector);
    };

    let hide = () => {
        selects.forEach((select) => {
            select.style.display = "none";
        });
    };

    return {
        init: init,
    };
})();

export default DmaSelect;
