import { polyfill } from "seamless-scroll-polyfill";

/**
 * Async Forms
 */
import AsyncFormSubmitter from "../modules/async-formsubmitter";
let forms = document.querySelectorAll("form");
if (forms.length) {
    forms.forEach((form) => {
        AsyncFormSubmitter.init(form);
    });
}

/**
 * DMA Select
 */
import DmaSelect from "../modules/dmaSelect";
DmaSelect.init();

/**
 * Set css variable for mobile 100vh
 */
const viewportHeight = () => {
    document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`);
};
window.addEventListener("resize", viewportHeight);
viewportHeight();

/**
 * Slideshow
 */
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
// init Swiper:
var ce_slideshow = new Swiper(".ce_slideshow .slide-container", {
    loop: true,
    direction: "horizontal",
    slidesPerView: 1.8,
    spaceBetween: 20,
    centeredSlides: true,
    loopedSlides: 0,
    speed: 1000,
    breakpoints: {
        750: {
            slidesPerView: 3.3,
            spaceBetween: 20,
        },
    },

    navigation: {
        nextEl: ".slideshow-next",
        prevEl: ".slideshow-prev",
    },
});

/**
 * Pageheader Slideshow
 */
var pageheader = new Swiper(".pageheader .slide-container", {
    loop: true,
    direction: "horizontal",
    slidesPerView: 1,
    speed: 2000,
    autoplay: {
        delay: 5000,
    },
});

/**
 * Mobile Detection
 */
var MobileDetect = require("mobile-detect");
var md = new MobileDetect(window.navigator.userAgent);

/**
 * Body Class on DomReady
 */
window.onload = function () {
    document.getElementsByTagName("body")[0].classList.add("domReady");
};

/**
 * Dom Ready
 */
(function () {
    /**
     * Slideshow Filter
     */
    var radios = document.querySelectorAll(".ce_slideshow .slide-filter input");
    var select = document.getElementById("slide-filter-select");
    var slides = document.querySelectorAll(".ce_slideshow .swiper-slide:not(.swiper-slide-duplicate):not(.swiper-slide-duplicate-prev)");
    for (var i = 0; i < radios.length; i++) {
        radios[i].addEventListener("change", function () {
            var radioValue = this.value;

            // Reset slider (remove all and append initial slides)
            ce_slideshow.removeAllSlides();
            ce_slideshow.appendSlide(slides);

            var arrRemoveSlides = [];
            slides.forEach(function (slide, index) {
                // Remove slides if not in filter array
                var arrFilter = slide.getAttribute("data-filter").split(",");
                if (radioValue != "all" && !arrFilter.includes(radioValue)) {
                    arrRemoveSlides.push(index);
                }
            });
            ce_slideshow.removeSlide(arrRemoveSlides);
            ce_slideshow.update();
        });
    }
    if (select) {
        select.addEventListener("change", function () {
            var selectValue = this.value;
            // Reset slider (remove all and append initial slides)
            ce_slideshow.removeAllSlides();
            ce_slideshow.appendSlide(slides);
            var arrRemoveSlides = [];
            slides.forEach(function (slide, index) {
                // Remove slides if not in filter array
                var arrFilter = slide.getAttribute("data-filter").split(",");
                if (selectValue != "all" && !arrFilter.includes(selectValue)) {
                    arrRemoveSlides.push(index);
                }
            });
            ce_slideshow.removeSlide(arrRemoveSlides);
            ce_slideshow.update();
        });
    }

    /**
     * Element in Viewport
     */
    var inViewport = require("in-viewport");
    var elements = document.querySelectorAll("[class^=ce_]");
    // var elements = document.querySelectorAll(".ce_twoColTextImage, .ce_teaser");

    elements.forEach(function (el) {
        if (md.mobile()) {
            inViewport(el, { offset: (el.offsetHeight / 4) * -1 }, visible);
        } else {
            inViewport(el, { offset: el.offsetHeight * -1 }, visible);
        }
    });

    function visible(elt) {
        elt.classList.add("inViewport");
    }

    /**
     * Disabled links
     */
    let buttonElements = document.querySelector(".btn");
    if (buttonElements) {
        if (buttonElements.classList.contains("disabled")) {
            buttonElements.addEventListener("click", function (event) {
                event.preventDefault();
            });
        }
    }

    /**
     * Jumplink in header
     */
    polyfill();
    let scrollToContentButton = document.getElementById("goToContentStart");
    if (scrollToContentButton) {
        scrollToContentButton.addEventListener("click", function (event) {
            let el = document.getElementById("contentStart");
            if (el) {
                el.scrollIntoView({
                    behavior: "smooth",
                });
            }
            event.preventDefault();
            return false;
        });
    }

    /**
     * Anchornavigation
     */
    let anchornavigation = document.querySelectorAll(".anchorNavigation .inner a");
    if (anchornavigation) {
        anchornavigation.forEach(function (element) {
            element.addEventListener("click", function (event) {
                var href = this.href.replace(/^.*\/\/[^\/]+\/#/, "");
                var target = document.querySelector('a[name="' + href + '"]');
                target.scrollIntoView({
                    behavior: "smooth",
                });

                // close navigation
                let toggler = document.getElementById("hamburg");
                toggler.checked = false;
                document.body.className = document.body.className.replace("nav-open", "");
                event.preventDefault();
            });
        });
    }

    /**
     * News & Eventsfilter
     */
    let filter = document.querySelectorAll(".filter");
    if (filter) {
        filter.forEach(function (f) {
            let filterItems = f.querySelectorAll("li");
            let cards = f.parentElement.querySelectorAll(".teaser");
            filterItems.forEach(function (item) {
                item.addEventListener("click", function (event) {
                    filterItems.forEach(function (i) {
                        i.classList.remove("active");
                    });

                    let opt = this.getAttribute("data-filter");

                    item.classList.add("active");

                    cards.forEach(function (c) {
                        // show all cards
                        c.classList.remove("hide");

                        // hide cards
                        let time = c.getAttribute("data-tstamp") * 1000;
                        let limit = new Date();

                        if (opt != "all") {
                            switch (opt) {
                                case "week":
                                    limit.setDate(limit.getDate() + 7);
                                    break;
                                case "month":
                                    limit.setDate(limit.getDate() + 31);
                                    break;
                                case "year":
                                    limit.setDate(limit.getDate() + 365);
                                    break;
                            }

                            if (time > limit.getTime()) {
                                c.classList.add("hide");
                            }
                        }
                    });
                });
            });
        });
    }

    /**
     * Adjust pageheader height for mobile devices
     */
    // if (md.mobile()) {
    //     let pageHeader = document.getElementsByClassName("pageheader");
    //     if (pageHeader.length) {
    //         let newHeight = window.innerHeight - 100;
    //         pageHeader[0].style.height = newHeight;
    //     }
    // }

    /**
     * Video Player
     */
    var player = document.querySelectorAll(".ce_player");
    player.forEach(function (el) {
        var playbutton = el.querySelectorAll(".playbutton");
        var video = el.querySelectorAll("video");

        video[0].addEventListener("click", function (event) {
            if (video[0].paused === false) {
                playbutton[0].style.display = "block";
            } else {
                playbutton[0].style.display = "none";
            }
        });
    });

    /**
     * Modal
     */
    var modal = document.querySelectorAll(".modal");
    modal.forEach(function (el) {
        if (!document.cookie) {
            el.classList.remove("hide");
            var close = el.querySelector(".close");
            close.addEventListener("click", function (event) {
                el.classList.add("hide");
                setcookie("hideStartModal", "true");
            });
        }
    });

    /**
     * Konfigurator
     */
    var kHandle = document.querySelector(".konfigurator .handle");
    var kApp = document.querySelector(".konfigurator #dma-app");
    var dmaselectInit = false;

    if (kHandle) {
        kHandle.addEventListener("click", function (event) {
            if (false === dmaselectInit) {
                dmaselectInit = true;
                DmaSelect.init();
            }
            kApp.classList.toggle("show");
            var kClose = document.querySelector(".konfigurator .close");
            kClose.addEventListener("click", function (event) {
                kApp.classList.remove("show");
            });
        });
    }
})();

function setcookie(cookieName, cookieValue) {
    var today = new Date();
    var expire = new Date();
    expire.setTime(today.getTime() + 3600000 * 24 * 14);
    document.cookie = cookieName + "=" + encodeURI(cookieValue) + ";expires=" + expire.toGMTString();
}
