import qs from "qs";
import DmaSelect from "../modules/dmaSelect";
const axios = require("axios").default;

var AsyncFormSubmitter = (() => {
    const cssClassSending = "js--form-sending";

    let form = null;

    let inputs;

    let formId;

    let formIsValid;

    let submitForm = () => {
        let values = {};

        for (let i = 0; i < inputs.length; i++) {
            switch (inputs[i].type) {
                case "hidden":
                case "text":
                case "textarea":
                case "tel":
                case "file":
                case "email":
                case "select-one":
                    values[inputs[i].name] = inputs[i].value;
                    break;
            }
        }

        form.classList.add(cssClassSending);

        axios({
            method: form.method,
            url: form.action,
            data: qs.stringify(values),
            responseType: "document",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                // "Content-Type": "multipart/form-data; charset=UTF-8",
            },
        })
            .then((response) => {
                let responseForm = response.data.querySelectorAll('[data-formid="' + formId + '"]');
                let responsePage = response.data.querySelectorAll(".mod_article");
                if (responseForm.length === 1) {
                    responseForm = responseForm[0];
                    form.innerHTML = responseForm.innerHTML;

                    // to first error
                    let errors = form.querySelectorAll("p.error");
                    if (errors.length) {
                        errors[0].scrollIntoView({ behavior: "smooth", block: "center" });
                    } else {
                        form.scrollIntoView({ behavior: "smooth", block: "center" });
                    }
                } else {
                    form.outerHTML = responsePage[0].innerHTML;
                }
                form.classList.remove(cssClassSending);

                DmaSelect.init();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    let init = (formElement) => {
        form = formElement;

        formId = form.dataset.formid;
        inputs = form.elements;
        formIsValid = true;

        form.addEventListener("submit", (event) => {
            event.preventDefault();
            submitForm(form);
        });
    };

    return {
        init: init,
    };
})();

export default AsyncFormSubmitter;
